import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import axios from "axios";
import { SERVER_URL } from "src/serverConfig";
import { clientAPI } from "./axiosAnstance";
import {getAuthHeader} from "../../../jwt/config";

export const uploadRecoreAPI = createAsyncThunk(
  "recore/fichier",
  async (data) => {
    const fichi = JSON.stringify(data.toSave);

    const blob = new Blob([fichi], {
      type: "application/json",
    });

    var formData = new FormData();

    var theFile = data.file;

    formData.append("file", theFile);
    formData.append("meta", blob);
    let token = localStorage.getItem("token");

    const response = axios.post(
      SERVER_URL + `/api/recore/upload-file`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        Accept: "application/json",
      }
    );
    return (await response).data;
  }
);

export const saveRecoreAPI = createAsyncThunk(
  "recore/saveRecoreAPI",
  async (data) => {
    try {
      const response = await clientAPI.post("/api/recore/add", data);
      return response.data;
    } catch (error) {
      return 'il est un probléme au niveau de la API';
    }
  }
);

export const listTypeFichierSavedRecoreAPI = createAsyncThunk(
  "hajj/listTypeFichierSavedRecoreAPI",
  async (idDemande) => {
    const response = await clientAPI.get("/api/recore/listeTypeFichierSaved/" + idDemande);
    return response.data;
  }
);

export const getAnalyseRecoreFileAPI = createAsyncThunk(
  "recore/analyse",
  async (data) => {
    const response = await clientAPI.post("/api/recore/analyse", data);
    return response.data;
  }
);

export const listRecoreAPI = createAsyncThunk(
  "recore/listRecoreAPI",
  async (data) => {
    const url = `/api/recore/getDemandes`;
    const response = await clientAPI.get(url);
    return response.data;
  }
);
export const getFileByDemandeAndTypeRecore = createAsyncThunk(
  "recore/getFileByDemandeAndTypeRecore",
  async (data) => {
    return axios
      .get(
        SERVER_URL +
        `/api/recore/getFileByDemandeAndType/${data.idDemande}/${data.id_type_fichier}`,
        {
          headers: getAuthHeader(),
          responseType: "blob",
        }
      )
      .then((res) => {
        return res.data;
      });
  }
);

export const listDemandeFilterRecoreAPI = createAsyncThunk(
  "recore/listDemandeFilterRecoreAPI",
  async (data) => {
    //const url = `/api/colonie/listColonie`;
    const response = await clientAPI.post("/api/recore/filter",data);
    return response.data;
  }
);
export const verifierEligibiliterApi = createAsyncThunk(
  "recore/verifierEligibiliterApi",
  async (data) => {
    const response = await clientAPI.post("/api/recore/verifier",data);
    return response.data;
  }
);

export const getAnalyseRecoreTwoDatesAPI = createAsyncThunk(
  "recore/compare-dates",
  async (data) => {
    const response = await clientAPI.post("/api/recore/compare-dates", data);
    return response.data;
  }
);

export const getAllRecoreDataAPI = createAsyncThunk(
  "recore/listAll",
  async (data) => {
    const response = await clientAPI.get("/api/recore/list/" + data);
    return response.data;
  }
);

const RecoreSlice = createSlice({
  name: "recore",
  initialState: {
    details: {},
    detailsCompare: {},
    fileMeta: {},
    listeTypeFichierSaved : [],
    listeDemande:[],
    shouldShowAnalysisButton: false,
    failedToUpload: false,
  }, // enfants: []
  reducers: {
    setUploadedFileMetaAC: (state, action) => {
      state.fileMeta = action.payload;
    },
    setShouldShowAnalysisButtonAC: (state, action) => {
      state.shouldShowAnalysisButton = action.payload;
    },
    setUploadStatusAC: (state, action) => {
      state.failedToUpload = false;
    },
  },
  extraReducers: {
    [getAnalyseRecoreFileAPI.fulfilled]: (state, action) => {
      state.details = action.payload;
    },
    [getAnalyseRecoreTwoDatesAPI.fulfilled]: (state, action) => {
      state.detailsCompare = action.payload;
    },
    [listTypeFichierSavedRecoreAPI.fulfilled]: (state, action) => {
      state.listeTypeFichierSaved = action.payload;
    },
    [listRecoreAPI.fulfilled]: (state, action) => {
      state.listeDemande = action.payload;
    },
    [listDemandeFilterRecoreAPI.fulfilled]: (state, action) => {
      state.listeDemande = action.payload;
    },
    [getAllRecoreDataAPI.fulfilled]: (state, action) => {
      state.detailsAll = action.payload;
    },
    [uploadRecoreAPI.fulfilled]: (state, action) => {
      state.shouldShowAnalysisButton = true;
    },
    [uploadRecoreAPI.rejected]: (state, action) => {
      state.failedToUpload = true;
    },
  },
});

export const getRecoreFileMeta = (state) => state.recore.fileMeta;
export const getUploadStatus = (state) => state.recore.failedToUpload;
export const {
  setUploadedFileMetaAC,
  setShouldShowAnalysisButtonAC,
  setUploadStatusAC,
} = RecoreSlice.actions;

export default RecoreSlice.reducer;
