import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientAPI } from "./axiosAnstance";

export const getListSoldeByCompteAPI = createAsyncThunk(
  "soldeSlice/getListSoldeByCompte",
  async (idCompte) => {
    const response = await clientAPI.get("/api/recover/getSolde/" + idCompte);
    return response.data;
  }
);
export const getListprestationCompteAPI = createAsyncThunk(
  "soldeSlice/getListprestationCompteAPI",
  async (idCompte) => {
    const response = await clientAPI.get("/api/recover/prestationByCompte/" + idCompte);
    return response.data;
  }
);

const SoldeSlice = createSlice({
  name: "recover",
  initialState: {
    loadingStatus: null,
    soldeList: [] ,
    prestationList:{},
  },

  reducers: {},
  extraReducers: {
    [getListSoldeByCompteAPI.pending]: (state, action) => {
      state.loadingStatus = "pending";
      state.soldeList = null;
    },
    [getListSoldeByCompteAPI.fulfilled]: (state, action) => {
      state.loadingStatus = "fulfilled";
      state.soldeList = action.payload;
    },
    [getListSoldeByCompteAPI.rejected]: (state, action) => {
      state.loadingStatus = "rejected";
    },
    [getListprestationCompteAPI.fulfilled]: (state, action) => {
      state.prestationList = action.payload;
      console.log('liste prestation :::  ',state.prestationList)
    },
  },
});

export const {} = SoldeSlice.actions;

export default SoldeSlice.reducer;
